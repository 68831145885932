<template>
  <router-view />
</template>

<script>

import { mapState } from 'vuex';
export default {
  computed: {
    ...mapState('accounts', ['plans']),
  },
  async created() {
    try {
      /* eslint no-unused-vars: 0 */
      const plansCount = this.plans.items.length;
    } catch (error) {
      await this.$store.dispatch('accounts/plans');
    }
  }
};
</script>